



import { VeeValidateConfig } from 'vee-validate/dist/types/config';
import { LoginRequest } from '../../../../types/typescript-axios/api';
import { Component, Vue, Emit } from 'vue-property-decorator';
import VeeValidate from '../../../plugins/vee-validate';
import store from '../../../store';
import StepNavi from '../../../components/contents/stepNavi/StepNavi.vue';
import axios, {AxiosResponse, AxiosError} from 'axios';
import { ErrorResponse } from '../../../../types/typescript-axios/api';
import VueSimpleSuggest from 'vue-simple-suggest';
import 'vue-simple-suggest/dist/styles.css';
import { getSuggestionList } from '@/plugins/helper';
import { load } from 'recaptcha-v3';
import auth from '../../../store/auth';

Component.registerHooks([
  'beforeRouteEnter',
]);

@Component({
  components: {
    StepNavi,
    VueSimpleSuggest,
  },
  mixins: [ VeeValidate ],
})
export default class AccountEditAccountIndex extends Vue {
  private errorMessage: boolean = false;

  private companyNames: string[] = [];

  get fromPath(): string|null {
    return sessionStorage.getItem('fromPath');
  }

  private get stepNavi(): string {
    const $parent: any = this.$parent;
    return $parent.stepNavi;
  }

  private get status(): string {
    const $parent: any = this.$parent;
    return $parent.status;
  }

  private get companyOptions(): string[] {
    const $parent: any = this.$parent;
    return $parent.companyOptions;
  }

  private get birthdayOptions(): number[] {
    const $parent: any = this.$parent;
    return $parent.birthdayOptions;
  }

  private get agree(): string {
    const $parent: any = this.$parent;
    return $parent.agree;
  }
  private set agree(value: string) {
    this.inputAgree(value);
  }
  private get mail(): string {
    const $parent: any = this.$parent;
    return $parent.mail;
  }
  private get name1(): string {
    const $parent: any = this.$parent;
    return $parent.name1;
  }
  private set name1(value: string) {
    this.inputName1(value);
  }
  private get name2(): string {
    const $parent: any = this.$parent;
    return $parent.name2;
  }
  private set name2(value: string) {
    this.inputName2(value);
  }
  private get birthYear(): string {
    const $parent: any = this.$parent;
    return $parent.birthYear;
  }
  private set birthYear(value: string) {
    this.inputBirthYear(value);
  }
  private get companyName(): string {
    const $parent: any = this.$parent;
    return $parent.companyName;
  }
  private set companyName(value: string) {
    this.inputCompanyName(value);
  }
  private get departmentName(): string {
    const $parent: any = this.$parent;
    return $parent.departmentName;
  }
  private set departmentName(value: string) {
    this.inputDepartmentName(value);
  }
  private get sex(): string {
    const $parent: any = this.$parent;
    return $parent.sex;
  }
  private set sex(value: string) {
    this.inputSex(value);
  }
  private get consent(): string {
    const $parent: any = this.$parent;
    return $parent.consent;
  }
  private set consent(value: string) {
    this.inputConsent(value);
  }
  private get password(): string {
    const $parent: any = this.$parent;
    return $parent.password;
  }
  private set password(value: string) {
    this.inputPassword(value);
  }
  private get newPassword(): string {
    const $parent: any = this.$parent;
    return $parent.newPassword;
  }
  private set newPassword(value: string) {
    this.inputNewPassword(value);
  }
  private get passwordReInput(): string {
    const $parent: any = this.$parent;
    return $parent.passwordReInput;
  }
  private set passwordReInput(value: string) {
    this.inputPasswordReInput(value);
  }
  private get newPasswordReInput(): string {
    const $parent: any = this.$parent;
    return $parent.newPasswordReInput;
  }
  private set newPasswordReInput(value: string) {
    this.inputNewPasswordReInput(value);
  }
  private get secretQuestAans(): string {
    const $parent: any = this.$parent;
    return $parent.secretQuestAans;
  }
  private set secretQuestAans(value: string) {
    this.inputSecretQuestAans(value);
  }
  private get tel(): string {
    return this.tel1 + this.tel2 + this.tel3;
  }
  private get tel1(): string {
    const $parent: any = this.$parent;
    return $parent.tel1;
  }
  private set tel1(value: string) {
    this.inputTel1(value);
  }
  private get tel2(): string {
    const $parent: any = this.$parent;
    return $parent.tel2;
  }
  private set tel2(value: string) {
    this.inputTel2(value);
  }
  private get tel3(): string {
    const $parent: any = this.$parent;
    return $parent.tel3;
  }
  private set tel3(value: string) {
    this.inputTel3(value);
  }

  @Emit()
  private inputAgree(value: string) {
    const $parent: any = this.$parent;
    $parent.setAgree(value);
  }
  @Emit()
  private inputName1(value: string) {
    const $parent: any = this.$parent;
    $parent.setName1(value);
  }
  @Emit()
  private inputName2(value: string) {
    const $parent: any = this.$parent;
    $parent.setName2(value);
  }
  @Emit()
  private inputBirthYear(value: string) {
    const $parent: any = this.$parent;
    $parent.setBirthYear(value);
  }
  @Emit()
  private inputCompanyName(value: string) {
    const $parent: any = this.$parent;
    $parent.setCompanyName(value);
  }
  @Emit()
  private inputDepartmentName(value: string) {
    const $parent: any = this.$parent;
    $parent.setDepartmentName(value);
  }
  @Emit()
  private inputSex(value: string) {
    const $parent: any = this.$parent;
    $parent.setSex(value);
  }
  @Emit()
  private inputConsent(value: string) {
    const $parent: any = this.$parent;
    $parent.setConsent(value);
  }
  @Emit()
  private inputPassword(value: string) {
    const $parent: any = this.$parent;
    $parent.setPassword(value);
  }
  @Emit()
  private inputNewPassword(value: string) {
    const $parent: any = this.$parent;
    $parent.setNewPassword(value);
  }
  @Emit()
  private inputPasswordReInput(value: string) {
    const $parent: any = this.$parent;
    $parent.setPasswordReInput(value);
  }
  @Emit()
  private inputNewPasswordReInput(value: string) {
    const $parent: any = this.$parent;
    $parent.setNewPasswordReInput(value);
  }
  @Emit()
  private inputSecretQuestAans(value: string) {
    const $parent: any = this.$parent;
    $parent.setSecretQuestAans(value);
  }
  @Emit()
  private inputTel1(value: string) {
    const $parent: any = this.$parent;
    $parent.setTel1(value);
  }
  @Emit()
  private inputTel2(value: string) {
    const $parent: any = this.$parent;
    $parent.setTel2(value);
  }
  @Emit()
  private inputTel3(value: string) {
    const $parent: any = this.$parent;
    $parent.setTel3(value);
  }

  @Emit()
  private onBlurTelFields() {
    const $observer: any = this.$refs.observerTel;
    $observer.validate();
  }

  @Emit()
  private async onSubmit() {
    const $observer: any = this.$refs.observer;
    const errorDome: any = this.$refs.errorMessage;
    $observer.reset();
    $observer.validate().then(async (result: boolean) => {
      if (result) {
        const $parent: any = this.$parent;
        const params: any = {
          password: this.password,
          username: $parent.beforeMail,
        };
        const $param = params;
        const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY);
        const token = await recaptcha.execute('homepage');
        $param.recaptchaToken = token;
        $param.token = auth.state.token;
        const nonce = await store.dispatch('nonce/getData', null , {root: true});
        const query = new URLSearchParams(nonce);
        await axios.post(
          '/wp-json/moc/auth/check?' + query.toString(),
          params,
        )
        .then((response: any) => {
          if (response.data.success) {
            this.$router.push('/account/edit_account/confirm/');
          } else {
            this.errorMessage = true;
            Vue.nextTick()
            .then(() => {
              window.scrollTo({
                top: window.pageYOffset + errorDome.getBoundingClientRect().top,
                behavior: 'smooth',
              });
            });
          }
        }, (error: any) => {
          this.errorMessage = true;
          Vue.nextTick()
          .then(() => {
            window.scrollTo({
              top: window.pageYOffset + errorDome.getBoundingClientRect().top,
              behavior: 'smooth',
            });
          });
        });
      } else {
        this.errorMessage = true;
        Vue.nextTick()
        .then(() => {
          window.scrollTo({
            top: window.pageYOffset + errorDome.getBoundingClientRect().top,
            behavior: 'smooth',
          });
        });
      }
    });
  }

  @Emit()
  private backpage(): void {
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
  }

  @Emit()
  private suggestOff() {
    const ref: any = this.$refs;
    ref.suggest.hideList();
  }

  @Emit()
  private suggestOn() {
    const ref: any = this.$refs;
    ref.suggest.showList();
  }

  @Emit()
  private companyBlur() {
    window.setTimeout(() => this.suggestOff(), 300);
  }

  @Emit()
  private async suggestionList() {
    return await axios.get('/wp-json/moc/company')
    .then((res) => this.companyNames = res.data )
    .catch( (e: AxiosError<ErrorResponse>) => {
      console.log(e);
    });
  }
}
